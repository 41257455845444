/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/index.css */
@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-Regular.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

html,
body {
  overscroll-behavior: none;
}

body {
  background-color: #fff;
  transition: all 0.5s ease-in-out;
}
